/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  IApplicationFooterItemComponentModel,
  IApplicationFooterScreenModel,
  ScreenType,
  useConfigurationSelector,
} from "@nf/common";
import React, { useMemo } from "react";
import dynamic from "next/dynamic";
import cx from "classnames";

const LanguageDropdown = dynamic(
  () => import("components/LanguageDropdown/LanguageDropdown"),
  { ssr: false }
);

const FooterLogo = dynamic(
  () => import("../../../resources/footer/app-footer-logo.svg")
);

export interface IAppFooterMenu {
  config?: any;
  isAppFooterTabletMobileDisplayed?: boolean;
}

export const AppFooterMenu = ({
  config,
  isAppFooterTabletMobileDisplayed,
}: IAppFooterMenu) => {
  const configuration = useConfigurationSelector();
  const conf = config || configuration;

  const footerColumns = useMemo(() => {
    let view: React.ReactNode = null;

    if (conf && conf.Screens) {
      const footerScreen = conf.Screens[
        ScreenType.ApplicationFooter
      ] as IApplicationFooterScreenModel;

      if (footerScreen && footerScreen.Components) {
        view = footerScreen.Components.map(
          ({ Action, Title }: IApplicationFooterItemComponentModel) => {
            return Action?.Url && Title ? (
              <div className="footerColumn" key={Title}>
                <a href={Action.Url} target="_blank" rel="noreferrer">
                  {Title}
                </a>
              </div>
            ) : null;
          }
        );
      }
    }

    return view;
  }, [conf]);

  return (
    <footer
      className={cx("appFooter", {
        appFooterTabletMobileDisplayed: isAppFooterTabletMobileDisplayed,
      })}
    >
      <div className="appFooterContent">
        <div className="footerLogo">
          <a
            href="https://www.nordiskfilm.com/"
            target="_blank"
            rel="noreferrer"
          >
            <FooterLogo />
          </a>
        </div>
        <div className="footerColumns">
          {footerColumns}
          <div className={cx("footerColumn", "footerColumnDropdown")}>
            <LanguageDropdown />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default AppFooterMenu;
